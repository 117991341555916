import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"

const HomePage = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicHomepage || {};
    return (
        <>
            <SliceZone
                body={pageContent.data.body}
                page="home"
            />
        </>
    );
};

export const Head = () => (
    <Seo title="Minnesota-mallista riippuvuuksien hoitoa" />
);

export const query = graphql`
  query HomePageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
        lang
        data {
            body {
              ... on PrismicHomepageDataBodyHero {
                    id
                    slice_type
                    primary {
                      content_type
                      content_link {
                        url
                      }
                      hero_content
                      hero_title {
                        richText
                      }
                      background_image {
                          url
                          fluid {
                            src
                          }
                          gatsbyImageData
                       }
                    }
               }
               
               ... on PrismicHomepageDataBodyGauge {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    reference
                  }
                  items {
                    label
                    value
                    maxvalue
                    type
                  }
                }
        
               ... on PrismicHomepageDataBodyTextSection {
                  id
                  slice_type
                  primary {
                    section_text {
                      richText
                    }
                    section_title {
                      richText
                    }
                  }
                  items {
                    image {
                      fluid {
                        src
                      }
                      alt
                    }
                    image_text {
                      richText
                    }
                    image_title {
                      richText
                    }
                  }
               }
               ... on PrismicHomepageDataBodyImagecard {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    content {
                      richText
                    }
                  }
                  items {
                    image {
                      fluid {
                        src
                      }
                    }
                    image_title {
                      richText
                    }
                    page_link {
                      url
                    }
                  }
               }
               ... on PrismicHomepageDataBodyCallToAction {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    paragraph {
                      richText
                    }
                    icon_image {
                      fluid {
                        src
                      }
                    }
                    button_link {
                      url
                    }
                    button_label
                  }
                }
                ... on PrismicHomepageDataBodyVideoGallery {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    description {
                      richText
                    }
                  }
                  items {
                     video_title {
                      richText
                    }
                    video_url {
                      embed_url
                      html
                      id
                    }
                  }
                }
            }
        }
    }
  }
`

export default HomePage;



